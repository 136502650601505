<template>
  <el-main>
    <div class="user-info">
      <div class="title">
        1、用户信息
        <div class="type">
          <el-button size="small">{{ info.enterprise_type == 1 ? '工厂店' : '广告店' }}</el-button>
          <el-button size="small">{{ info.service_type == 1 ? '广告类' : info.service_type == 2 ? '安装类' : '设计类' }}</el-button>
        </div>
      </div>
      <div class="info">
        <div class="item">
          会员头像：
          <el-image :src="info.avatar"></el-image>
        </div>
        <div class="item">
          会员昵称：
          {{ info.nickname }}
        </div>
        <div class="item">
          店铺/师傅名称：
          {{ info.name }}
        </div>
        <div class="item">
          手机号：
          {{ info.mobile }}
        </div>
        <div class="item">
          用户来源：
          <img :src="info.comefrom" alt="" />
        </div>
        <div class="item">
          注册时间：
          {{ getDateformat(info.create_time) }}
        </div>
      </div>
    </div>
    <div class="assets">
      <div class="title">2、资产信息</div>
      <el-table :data="assets" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="amount" label="总收益" align="center">
          <template v-slot="{ row }">
            <span>{{ row.amount }}</span>
            <el-button @click="$router.push({ path: '/finance/memberAssets', query: { keyworks: info.name } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="goods_sales_amount" label="商品佣金" align="center"></el-table-column> -->
        <el-table-column prop="distribution_amount" label="服务佣金" align="center">
          <template v-slot="{ row }">
            <span>{{ row.distribution_amount }}</span>
            <el-button @click="$router.push({ path: '/finance/memberAssets', query: { keyworks: info.name, service_type: 3 } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="service_sales_amount" label="服务费用" align="center">
          <template v-slot="{ row }">
            <span>{{ row.goods_sales_amount }}</span>
            <el-button @click="$router.push({ path: '/finance/memberAssets', query: { keyworks: info.name, service_type: 2 } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="goods_sales_amount" label="商品费用" align="center">
          <template v-slot="{ row }">
            <span>{{ row.service_sales_amount }}</span>
            <el-button @click="$router.push({ path: '/finance/memberAssets', query: { keyworks: info.name, service_type: 1 } })" style="margin-left: 10px" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="frozen_sum" label="冻结金额" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="withdrawal">
      <div class="title">3、提现情况</div>
      <el-table :data="withdrawal" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="applysn" label="提现单号" align="center"></el-table-column>
        <el-table-column prop="applyprice" label="申请金额" align="center"></el-table-column>
        <el-table-column prop="accountprice" label="打款金额" align="center"></el-table-column>
        <el-table-column prop="status" label="结算状态" align="center">
          <template v-slot="{ row }">
            {{ row.status == 0 ? '待审核' : row.status == 1 ? '待结算' : row.status == 2 ? '已结算' : '已驳回' }}
          </template>
        </el-table-column>
        <el-table-column prop="apply_sum" label="申请时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
      </el-table>
      <Paging v-if="total_number > 10" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </div>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      info: {},
      assets: [],
      withdrawal: [],
      page: 1,
      rows: 10,
      total_number: 0,
    };
  },
  created() {
    this.getList();
    this.$axios.post(this.$api.samecity.rights.eliminateMemberInfo, { id: this.$route.query.id }).then(res => {
      if (res.code == 0) {
        let info = res.result;
        info.comefrom = info.comefrom == 1 ? wx : info.comefrom == 2 ? ali : info.comefrom == 3 ? fash : info.comefrom == 4 ? douyin : info.comefrom == 5 ? qq : baidu;
        this.assets = [
          {
            amount: info.amount,
            goods_sales_amount: info.goods_sales_amount,
            service_sales_amount: info.service_sales_amount,
            distribution_amount: info.distribution_amount,
            frozen_sum: info.frozen_sum || '0.00',
          },
        ];
        this.info = info;
      }
    });
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getList() {
      this.$axios
        .post(this.$api.samecity.rights.applyLogList, {
          rows: this.rows,
          page: this.page,
          id: this.$route.query.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.withdrawal = res.result.list;
            this.total_number = res.result.total_number;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .title {
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .level {
      margin-left: 20px;
      color: #409eff;
      padding: 5px 15px;
      border: 1px solid #409eff;
    }
  }
  .info {
    display: flex;
    padding-left: 40px;
    margin-bottom: 20px;
    .item {
      display: flex;
      align-items: center;
      margin-right: 50px;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
  }
  .el-table {
    margin-bottom: 20px;
  }
  .user-info .title {
    justify-content: flex-start;
    .type {
      margin-left: 15px;
    }
  }
}
</style>